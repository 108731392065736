.slideshow{
    position: relative;
}
.banner-box{
    position: absolute;
    top: 5rem;
    width: 100% !important;
    margin-left: 60px !important;
}
.banner .slideshow button{
    box-shadow: none !important;
}


/* Media query  */
@media screen and ( max-width : 1100px ) and ( min-width : 768px ) {
    .banner-box {
        padding-top: 5.5rem !important;
        margin-left: 80px !important;
    }
}

/* Mobile  */
@media screen and ( max-width : 480px ) {
    .banner-box {
        top: 4.5rem;
        margin-left: 0px !important;
    }
    .banner .slideshow button{
        width: 10px !important;
        height: 10px !important;
        margin: 0px 5px !important;
    }
    
}






