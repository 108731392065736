@import url('https://fonts.googleapis.com/css2?family=Inria+Sans:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');

:root{
  --active-color : #D9D9D9;
  --footer-color : #748489;
  --light : #667085;
}
body{
  font-family: 'Inria Sans', sans-serif !important;
}
a{
  text-decoration: none !important;
}
button {
  background: transparent;
  border: none;
}

.light-text{
  color : #667085;
}
.back-light{
  background-color: #F1F9FF;
}
.bg-light-color{
  background-color: #7E8D92;
}
.fs-9{
  font-size: 0.9rem!important;
}
.faint-txt{
  color: #5F6973;
}
.btn.btn-secondary {
  background: transparent;
  color: black;
  border-radius: 5px;
}
.btn.btn-primary {
  background-color: #667085 !important;
  border: 1px #667085;
  color: white;
}
.btn.btn-primary:hover {
  background-color: #414754 !important;
}
.light-color{
  color: var(--light);
}
.after-heading h1 {
  margin-bottom: -22px;
  position: relative;
}
.after-heading::after{
  content: '';
  padding: 0rem 8rem;
  font-size: 12px;
  background-color: #F1F9FF;
}



/* Input number text arrows */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}




/* Media Query  */

/* Mobile  */
@media screen and ( max-width : 480px ) {
  .after-heading::after {
    padding: 0rem 5rem;
  }
}

/* Tablet  */
@media screen and ( min-width : 480px ) and ( max-width : 1200px ) {
    
}

