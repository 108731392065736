#root{
  overflow-x: hidden;
}


/* Loader  */
.loader {
  display: inline-block;
}

.loader:after {
  content: " ";
  display: block;
  width: 80px;
  height: 80px;
  margin: 1px;
  border-radius: 50%;
  border: 5px solid #fff;
  border-color: #667085 transparent #667085 transparent;
  animation: loader 1.2s linear infinite;
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.mar-y{
  margin-top: 20rem;
}
