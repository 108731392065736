/* Banner  */
.bg-hero-text{
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 8px;
}
.banner-box{
    padding-top: 16.5rem;
}


/* Banner bottom  */
.bg-banner-bottom{
    background-color: rgba(126, 141, 146, 1);
}
.bg-banner-bottom .msg {
    padding: 4rem 0;
}


/* About  */
.home-about {
    padding-top: 7rem;
    padding-left: 6rem;
}
.home-about p {
    padding-right: 10rem;
}


/* Projects  */
.bg-home-projects{
    background: linear-gradient(180deg, #F1F9FF 0%, rgba(241, 249, 255, 0) 100%);
}


/* Architecture and Interior */
.bg-architecture{
    background-color: #F7FCFC;
}
.architecture{
    padding-bottom: 3rem;
}
.architecture-img{
    padding: 7rem 7rem 0;
}
.inside-img img {
    border-radius: 15px;
}
.architecture-img .inside-img{
    padding: 0.9rem;
    background-color: rgb(203 203 203);
    border-radius: 15px;
}
.font-big{
    font-size: 4.5rem;
    color: rgba(150, 150, 150, 0.25);
}
.architecture-text{
    padding: 1.5rem 0;
}
.architecture-text .inside-div {
    padding-right: 10rem;
}
.last-start img{
    width: 8%;
}
.bg-work{
    background-color: #667085;
    border-radius: 52px 0px 52px 52px;
}


/* Process / how we work  */
.process{
    background: linear-gradient(150deg, #b2bac0 20%, rgb(255 255 255 / 0%) 80%);
}
.process .work{
    position: relative;
}
.number{
    position: absolute;
    top: -25px;
    left: 0;
    color: #5F6973;
    font-size: 2.5rem!important;
    border: 1px dashed;
    border-radius: 50%;
    padding: 0rem 1.4rem;
}
.work.work-center {
    margin-top: -5rem;
}
.work .border-bootom{
    border-bottom: 1px solid;
    border-radius: 52px;
}
.work .bg-work {
    margin-bottom: 5px;
}
.work.work-center .border-bootom{
    background-color: #373E4A;
}


/* Why us  */
.sec-why svg{
    height: 3.5rem;
    width: 3.5rem;
    margin-bottom: 2rem;
}
.sec-why .internal-why{
    padding-right: 4rem;
}
.heading-style{
    padding: 5px 0;
    background-color: #F1F9FF;
    border-radius: 5px;
}


/* Contact Home  */
.contact-home{
    padding: 4rem 0;
}
.contact-font {
    font-size: 3rem;
    margin-bottom: 0;
    line-height: 4.5rem;
    padding-right: 4rem;
}





/* Media Query  */
@media screen and ( max-width : 1100px ) and ( min-width : 768px ) {
    .bg-banner-bottom .msg {
        padding: 3rem 0;
    }
    .home-about p {
        padding-right: 2rem;
    }
    .home-about {
        padding-top: 2rem;
        padding-left: 3rem;
    }
    .architecture-img {
        padding: 8rem 1rem 0 0;
    }
    .architecture-text .inside-div {
        padding-right: 0rem;
    }
    .work.work-center {
        margin-top: -3rem;
    }
    .sec-why .internal-why {
        padding-right: 1rem;
    }
    .contact-font {
        padding-right: 0rem;
    }
    .contact-home {
        padding: 3rem 0;
    }
}


@media screen and ( max-width : 480px ) {
    /* Banner  */
    .banner-box {
        padding-top: 4rem;
    }

    /* Banner bottom  */
    .bg-banner-bottom .msg {
        padding: 2.5rem 0;
    }

    /* About  */
    .home-about {
        padding-top: 1rem;
        padding-left: 2rem;
    }
    .home-about p {
        padding-right: 0rem;
    }
    .about-img img{
        width: 107.2%;
    }


    /* Architecture and Interior  */
    .architecture-img {
        padding: 1rem 0.5rem 0;
    }
    .architecture-text .inside-div {
        padding-right: 0rem;
    }
    .last-star img.img-fluid {
        width: 10%;
    }
    .font-big {
        font-size: 2.6rem;
    }
    .architecture {
        padding-bottom: 0rem;
    }
    .star img.img-fluid {
        width: 5%;
    }


    /* How we work  */
    .work.work-center {
        margin-top: 0;
    }
    .number {
        top: 25px;
    }


    /* Why us  */
    .after-heading h1 {
        margin-bottom: -16px;
    }
    .sec-why .internal-why {
        padding-right: 0rem;
        border: none !important;
    }
    .why-us .sec-why {
        padding-bottom: 1rem;
    }


    /* Contact home  */
    .contact-font {
        font-size: 1.4rem;
        margin-bottom: 0;
        line-height: 4.5rem;
        padding-right: 0rem;
    }
    .contact-home {
        padding: 2rem 0 3rem;
    }


}


/* S9+ and 5/SE */
@media screen and (max-width : 330px) {
    .col-6.py-1.star {
        width: 100% !important;
    }
    .star img.img-fluid {
        width: 7%;
    }
}


/* Fold  */
@media screen and (max-width : 300px) {
    .font-big {
        font-size: 2.3rem;
    }
    a.faint-txt svg {
        margin: 0 !important;
    }
    .contact-home {
        padding: 2rem 0 3rem 0.8rem !important;
    }
    .col-6.py-1.star {
        width: 100% !important;
    }
    .star img.img-fluid {
        width: 7%;
    }
    .about-img img {
        width: 109.3%;
    }
}











